import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ChangePassword from './components/ChangePassword';
import EditClient from './components/EditClient';

function App() {
  const [ user, setUser ] = useState(null);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setUser(token);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Header /> {/*ajout de l'en-tete*/}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/" />} />
          <Route path="/change-password" element={user ? <ChangePassword /> : <Navigate to="/" />} />
          <Route path="/edit/:clientId" element={user ? <EditClient /> : <Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
