import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import './EditClient.css';

const EditClient = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [message, setMessage] = useState('');
  
  const token = localStorage.getItem('token');
  
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const iv = process.env.REACT_APP_ENCRYPTION_IV;

  const decryptData = (encryptedData) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(encryptionKey), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText);
  };

  const loadClient = async () => {
    try {
      const response = await fetch(`/api/clients/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        setMessage('Vous devez vous connecter!');
        return;
      }
      
      const encryptedData = await response.text();
      const decryptedClient = decryptData(encryptedData);
      setClient(decryptedClient);
    } catch (error) {
      console.error('Erreur lors du chargement du client', error)
    }
  };

  useEffect(() => {
    loadClient();
  }, [clientId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/clients/&{clientId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(client),
      });

      if (response.ok || response.status === 201) {
        setMessage('Client mis a jour avec succes');
        navigate('/dashboard');
      } else {
        setMessage('Erreur lors de la mise a jour du client!');
      }
    } catch (error) {
      setMessage('Erreur lors de la mise a jour du client!');
    }
  };

  if (!client) return <p>Chargement...</p>;

  return (
    <div className="edit-container">
      <h2>Modifier le client</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="edit-form-group">
          <label htmlFor="id">ID</label>
          <label htmlFor="company-name">Nom de la societe</label>
        </div>
        <div className="edit-form-group">
          <input
            type="text"
            id="id"
            name="id"
            value={client.id}
            readOnly
          />
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={client.companyName}
            onChange={handleInputChange}
          />
        </div>

        <div className="edit-form-group">
          <label htmlFor="first-name">Nom</label>
          <label htmlFor="last-name">Prenom</label>
        </div>
        <div className="edit-form-group">
          <input
            type="text"
            id="first-name"
            name="firstName"
            value={client.firstName}
            onChange={handleInputChange}
          />
          <input
            type="text"
            id="last-name"
            name="lastName"
            value={client.lastName}
            onChange={handleInputChange}
          />
        </div>

        <div className="edit-form-group">
          <label htmlFor="email">Email</label>
          <label htmlFor="last-payment">Dernier paiement</label>
        </div>
        <div className="edit-form-group">
          <input
            type="email"
            id="email"
            name="email"
            value={client.email}
            onChange={handleInputChange}
          />
          <input
            type="date"
            id="last-payment"
            name="lastPaymentDate"
            value={client.lastPaymentDate.split('T')[0]}
            onChange={handleInputChange}
          />
        </div>

        <div className="edit-form-group">
          <div className="activation-key">
            <label>Clef d'activation</label>
            <div>{client.activationKey}</div>
          </div>
          <div className="checkbox-container">
            <label htmlFor="active-key">Cle Active</label>
            <input
              type="checkbox"
              id="active-key"
              name="isKeyActive"
              checked={client.isKeyActive}
              onChange={(e) => setClient(prevClient => ({
                ...prevClient,
                isKeyActive: e.target.checked
              }))}
            />
          </div>
        </div>
        <button type="submit" className="edit-button">Valider</button>
      </form>
    </div>
  );

};
export default EditClient;
