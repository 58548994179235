import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({ onLogin}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok || response.status === 201) {
        setErrorMessage('');
        localStorage.setItem('token', data.token);

        if (data.message === 'First Login' || data.message === 'Password Expired') {
          console.log(data.message);
          navigate('/change-password');
        } else if (data.message === 'Login Failed') { 
          setErrorMessage('Mauvais utilisateur ou mauvais mot de passe');
          navigate('/');
        } else {
          console.log(data.message);
          navigate('/dashboard');
        }
      } else {
        setErrorMessage(data.message || 'Erreur lors de la connexion');
      }
    } catch (error) {
      setErrorMessage('Erreur lors de la connexion!');
    }
  };

  return (
    <div className="login-container">
      <h2>Connexion</h2>
      <form onSubmit={handleSubmit}>
        {errorMessage && <p className='login-error'>{errorMessage}</p>}
        <input
          placeholder="Login:"
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          placeholder="Mot de passe:"
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Se Connecter</button>
      </form>
    </div>
  );
};

export default Login;
