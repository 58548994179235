import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ChangePassword.css';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const validateNewPassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage('Les nouveaux mot de passe ne correspondent pas.');
      return;
    }

    if (!validateNewPassword(newPassword)) {
      setErrorMessage('Le mot de passe doit contenir au moins 12 caracteres,une majuscule, une minuscule, un chiffre, et un caractere special.');
      return;
    }

    try {
      const response = await fetch('/api/auth/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      });

      const data = await response.json();

      if (response.ok || response.status === 201) {
        console.log("Retour du back: ", data.message);
        setErrorMessage('');
        navigate('/dashboard');
      } else {
        setErrorMessage(data.message || 'Erreur lors du changement de mot de passe!');
      }
    } catch (error) {
      setErrorMessage('Erreur lors du changement de mot de passe!');
    }
  };

  return (
    <div className="change-password-container">
      <form className="change-password-form" onSubmit={handleSubmit}>
        <h2>Changer mot de passe</h2>
        {errorMessage && <p className="change-password-error">{errorMessage}</p>}
        <div className="change-password-form-group">
          <label htmlFor="oldPassword">Ancien MDP</label>
          <input
            type="password"
            id="oldpassword"
            className="change-password-input-field"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="change-password-form-group">
          <label htmlFor="newPassword">Nouveau MDP</label>
          <input
            type="password"
            id="newPassword"
            className="change-password-input-field"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="change-password-form-group">
          <label htmlFor="confirmPassword">Confirmer MDP</label>
          <input
            type="password"
            id="confirmPassword"
            className="change-password-input-field"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="change-password-submit-button">Soumettre</button>
      </form>
    </div>
  );
};

export default ChangePassword;
