import React from 'react';
import './Header.css';

const Header = () => {

  return (
    <header className="header">
      <div className="header-content">
        <img src="http://softhealth.ddns.net/uploads/logo.png" alt="Logo" className="logo" />
        <span>
          <span className="header-red-letter">S</span>
          <span className="title">oft</span>
          <span className="header-blue-letter">H</span>
          <span className="title">ealth</span>
        </span>
      </div>
    </header>
  );
}

export default Header;
