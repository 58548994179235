import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import CryptoJS from 'crypto-js';

const Dashboard = () => {
  const [clients, setClients] = useState([]);
  const [numClients, setNumClients] = useState(1);
  const [message, setMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const token = localStorage.getItem('token');
 
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const iv = process.env.REACT_APP_ENCRYPTION_IV;

  const decryptData = (encryptedData) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(encryptionKey), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    const parsedData = JSON.parse(decryptedText);
    
    parsedData.forEach(client => {
      console.log('client.isKeyActive: ', client.isKeyActive);
      client.isKeyActive = client.isKeyActive === 'true' || client.isKeyActive === true;
    });
    return parsedData;
  };

  const loadClients = async () => {
    try {
      const response = await fetch('/api/clients', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        setMessage('Vous devez vous connecter!');
        return;
      }
      
      const encryptedData = await response.text();
      const decryptedClients = decryptData(encryptedData);
      setClients(decryptedClients);
    } catch (error) {
      console.error('Erreur lors du chargement des clients', error)
    }
  };

  useEffect(() => {
    loadClients();
  }, []);

  const createClients = async () => {
    try {
      const response = await fetch(`/api/clients/bulk-create/${numClients}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        setMessage('Vous devez vous connecter!');
        return;
      }

      if (response.ok || response.status === 201) {
        setMessage(`${numClients} clients crees avec succes.`);
        loadClients();
      } else {
        setMessage('Erreur lors de la creation des clients.');
      }
    } catch (error) {
      setMessage('Erreur lors de la creation des clients.');
    }
  };

  const handleDeleteClick = (clientId) => {
    setClientToDelete(clientId);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setClientToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`api/clients/${clientToDelete}`, {
        method: 'DELETE',
        headers: {
          'Authorization':`Bearer ${token}`,
        },
      });

      if (response.ok || response.status === 201) {
        setMessage('Client supprime avec succes.');
        loadClients();
      } else {
        setMessage('Erreur lors de la suppression du client.');
      }
    } catch (error) {
      setMessage('Erreur lors de la suppression du client.');
    } finally {
      setShowPopup(false);
      setClientToDelete(null);
    }
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <div className="create-clients-form">
          <input
            type="number"
            value={numClients}
            min= "1"
            onChange={(e) => setNumClients(e.target.value)}
          />
          <button onClick={createClients}>Creer {numClients} clients</button>
        </div>
      </div>
      {message && <p className="dashboard-error">{message}</p>}
      <div className="dashboard-client-table">
        <table>
          <thead>
            <tr>
              <th>Nom de la societe</th>
              <th className="small-column">Date Dernier Paiement</th>
              <th className="small-column">Clef active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.id}>
                <td>{client.companyName}</td>
                <td className="small-column">{client.lastPaymentDate}</td>
                <td className="small-column">
                  <input type="checkbox" checked={client.isKeyActive} disabled />
                </td>
                <td>
                  <Link to={`/edit/${client.id}`}>
                    <button className="table-button">Modifier</button>
                  </Link>
                  <button
                    className="table-button delete-button"
                    onClick={() => handleDeleteClick(client.id)}
                  >
                    Supprimer
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Etes-vous sur de vouloir supprimer ce client ?</h3>
            <button onClick={handleConfirmDelete} className="popup-button confirm-button">Oui</button>
            <button onClick={handleClosePopup} className="popup-button cancel-button">Non</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
